/* FOOTER */

.footer {
    @extend .fs-14,
    .bg-gray,
    .py-4,
    .text-center;

    &.footer--secondary {
        @extend .bg-white;
    }
}

.footer-menu {
    @extend .d-flex,
    .flex-wrap,
    .justify-content-center;
}

.footer-menu-item {
    @extend .py-1,
    .text-black;

    &:not(:last-child) {
        &:after {
            content: '|';
            @extend .d-inline-block,
            .mx-2;
        }
    }
}