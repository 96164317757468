/* HEADER */

.header {
    padding: 25px 0;
    @extend .has-bg,
    .d-block,
    .text-white;
    background-image: url('../img/bg/header-bg.jpeg');
}

.header__logo {
    @extend .w-100,
    .d-block;
    max-width: 200px;

    @include media-breakpoint-up(sm) {
        max-width: 300px;
    }
}