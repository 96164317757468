html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  // font-size: $body-font-size;
  // line-height: $body-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.45;
  font-weight: 600;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: 28px;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
  }

  @include media-breakpoint-up(md) {
    font-size: 48px;
  }
}

h2,
.h2 {
  font-size: 24px;

  @include media-breakpoint-up(sm) {
    font-size: 30px;
  }

  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {}

h5,
.h5 {}

h6,
.h6 {
  font-size: 16px;
}

button,
a {
  @include transition($base-animation);
  cursor: pointer;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

p {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.bold,
.strong,
strong,
b {
  font-weight: 600;
}