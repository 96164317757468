/* VARIABLES */

/*************************
	Base variables
*************************/
.font-400 {
	font-weight: 400 !important;
}

.font-500 {
	font-weight: 500 !important;
}

.font-600 {
	font-weight: 600 !important;
}

$base-animation: 250ms;

/*************************
	Fonts variables
*************************/

.font-icons {
	&:before {
		font-family: 'icomoon';
	}
}


/*************************
	Font path variable
*************************/
$font-path: "../fonts/";

/*************************
	Body variables
*************************/
$body-font-size: 16px;
$body-line-height: 1.8;


/*************************
	Heading variables
*************************/