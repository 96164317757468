/* VIDEO CTA */

.video-cta {
    @extend .d-block,
    .mx-auto,
    .shadow;
    max-width: 770px;
}

.video-cta__box {
    @extend .text-center,
    .text-white,
    .py-2,
    .font-600,
    .lead,
    .bg-primary;

    svg {
        width: 0.7em;
        height: 0.7em;
        vertical-align: baseline;
    }
}