/* BOOTSTRAP OVERRIDE */

.container {
    max-width: 980px;
}

@media only screen and (max-width: 500px) {
    .tap-image-size {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9;
        left: 0;
        bottom: 64px;
        top: 0;
    }
}